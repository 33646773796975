import { faArrowLeft, faBars, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import React, { useCallback, useState } from 'react'
import { type NavListItem } from 'types/navigation'
import LogoImage from '../LogoImage'
import NavItem from '../Navbar/NavItem'
import ThemeToggleButton from '../Navbar/ThemeToggleButton'
import { useTheme } from 'layout/ThemeProvider'
import Footer from '../Footer'
import { signOut, useSession } from 'next-auth/react'
import { useTranslation } from 'react-i18next'
import { Permission } from 'helpers/permissions'
import { Routes } from 'helpers/routes'
import LanguageChanger from '../LanguageChanger'

interface Props {
  children?: React.ReactNode
  menu: NavListItem[]
}

const Sidebar: React.FC<Props> = ({ children, menu }) => {
  const router = useRouter()
  const { theme } = useTheme()
  const { data: token } = useSession()
  const { t } = useTranslation()
  const [isSidebarOpen, setSidebarOpen] = useState(true)

  const handleSidebarState = useCallback((open: boolean) => {
    window.dispatchEvent(new Event('resize'))
    setSidebarOpen(open)
  }, [])
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        {/* Sidebar */}
        <aside className={`space-y-6 transition-all duration-300 ease-in-out my-4 ${isSidebarOpen ? 'w-64' : 'w-0 hidden'}`}>
          <div className="fixed flex flex-col gap-8">
            <div className="flex flex-row gap-4 mb-8 items-center justify-between">
              <h2 className={`text-2xl font-extrabold pl-3 ${isSidebarOpen ? 'block' : 'hidden'}`}><LogoImage /></h2>
              {/* Close Sidebar Button */}
              {isSidebarOpen && (
                <button
                  onClick={() => { handleSidebarState(false) }}
                  className="transform -translate-x-100% z-10 text-primary rounded-r px-3 py-1 hover:scale-125">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
              )}
            </div>
            <nav id="nav" className='px-2 flex flex-col gap-2'>
              {menu.map((item, index) => {
                const isActive = router.asPath === item.path
                return <NavItem key={index} item={item} tight highlight={isActive} />
              })}
              {token?.user.permissions.includes(Permission.ADMIN.VIEW.DASHBOARD) && (
                <NavItem
                  item={{
                    title: 'admin_area',
                    path: Routes.ADMIN.DASHBOARD,
                    iconLeft: {
                      icon: faLockOpen,
                      color: 'green-500'
                    }
                  }}
                  tight
                  highlight={router.asPath === '/admin/dashboard'}
                />
              )}
              <div className="flex flex-row gap-4 justify-between mt-8">
                <LanguageChanger />
                <ThemeToggleButton />
              </div>
            </nav>
          </div>
        </aside>

        {/* Main Content */}
        <div className="flex-1 flex flex-col overflow-hidden relative">
          {/* Appbar/Navbar */}
          <div className={`fixed px-4 w-full z-10 h-16 ${isSidebarOpen ? 'py-2 hidden' : 'block'} ${theme === 'dark' ? 'bg-dark-bg' : 'bg-light-bg'}`}>
            <div className="container mx-auto flex flex-row justify-between items-center" onClick={() => { handleSidebarState(!isSidebarOpen) }}>
              <div className={isSidebarOpen ? 'hidden' : 'block text-3xl'}>
                <FontAwesomeIcon icon={faBars} />
              </div>
              <div>
                <span className={isSidebarOpen ? 'hidden' : 'block mt-2'}>
                  <LogoImage />
                </span>
              </div>
              <div>
                {token && (
                  <button className="hidden md:flex items-center justify-between border-2 border-primary rounded-md px-3 mt-2" onClick={async () => { await signOut() }}>
                    {t('signout')}
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Content */}
          <main className="flex-1 overflow-x-hidden overflow-y-auto mt-16 flex flex-col justify-center">
            <div className="flex flex-col flex-grow w-screen md:w-full">
              {children}
            </div>
          </main>
        </div>
      </div>

      {/* Footer */}
      <Footer />

    </div>
  )
}

export default Sidebar
