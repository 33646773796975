export enum ErrorMessagesEnum {
  // Common
  UNAUTHORIZED = 'unauthorized',
  FORBIDDEN = 'forbidden',
  BAD_REQUEST = 'bad_request',
  METHOD_NOT_ALLOWED = 'method_not_allowed',
  NO_PERMISSION = 'no_permission',
  OPERATION_FAILED = 'operation_failed',
  USER_WITH_THIS_EMAIL_ALREADY_EXIST = 'user_with_this_email_already_exist',
  NAME_MUST_BE_UNIQUE = 'name_must_be_unique',
  TOO_MANY_REQUESTS = 'too_many_requests',
  TOKEN_NOT_FOUND = 'token_not_found',
  TOKEN_EXPIRED = 'token_expired',
  REQUIRED = 'required',
  EMAIL_NOT_VERIFIED = 'email_not_verified',
  PATH_INVALID = 'path_invalid',
  ANOUNCEMENT_NOT_FOUND = 'anouncement_not_found',
  SOME_FIELDS_MUST_BE_UNIQUE = 'some_fields_must_be_unique',
  THIS_FIELD_CANNOT_BE_UPDATED = 'this_field_cannot_be_updated',
  // Auth
  OLD_PASSWORD_INCORRECT = 'old_password_incorrect',
  PASSWORD_SIGNIN_UNAVAILABLE = 'password_signin_unavailable',
  PASSWORDS_DO_NOT_MATCH = 'passwords_do_not_match',
  PASSWORD_REQUIRED = 'password_required',
  PASSWORD_INCORRECT = 'password_incorrect',
  PASSWORD_NOT_STRONG_ENOUGH = 'password_not_strong_enough',
  SIGNUP_FAILED = 'signup_failed',
  EMAIL_NOT_FOUND = 'email_not_found',
  EMAIL_INVALID = 'email_invalid',
  REGISTRATION_DISABLED = 'registration_disabled',
  EMAIL_ALREADY_VERIFIED = 'email_already_verified',
  USER_NOT_FOUND = 'user_not_found',
  // Tools
  FEATURE_DISABLED = 'feature_disabled',
  THI1D_PARTY_API_ERROR = 'third_party_api_error',
  LAST_DEMO_REQUEST = 'last_demo_request',
  // Settings
  SETTING_NOT_FOUND = 'setting_not_found',
  INCORRECT_SETTING_TYPE = 'incorrect_setting_type',
  // Media
  MAX_WATCHLIST_LENGTH_REACHED = 'max_watchlist_length_reached',
  // Posts
  POST_NOT_FOUND = 'post_not_found',
  POST_TITLE_REQUIRED = 'post_title_required',
  POST_CONTENT_REQUIRED = 'post_content_required',
  TITLE_MUST_BE_UNIQUE = 'title_must_be_unique',
}
