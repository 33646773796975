export const Routes = {
  AUTH: {
    SIGNIN: '/signin',
    SIGNOUT: '/api/v1//auth/signout',
    REQUEST_PASSWORD_RESET: '/request-reset-password',
    PASSWORD_RESET: '/reset-password',
    VERIFY_EMAIL: '/verify-email'
  },
  PUBLIC: {
    HOME: '/',
    TV_GUIDE: '/tv-guide',
    POSTS: '/posts',
    WATCHLIST: '/watchlist',
    CONTACT: '/contact',
    ABOUT: '/about',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    FAQ: '/faq'
  },
  USER: {
    DASHBOARD: '/user',
    PROFILE: '/user/profile'
  },
  ADMIN: {
    DASHBOARD: '/admin',
    USERS: '/admin/users',
    POSTS: '/admin/posts',
    ROLES: '/admin/roles',
    EMAILS: '/admin/emails',
    SETTINGS: '/admin/settings'
  }
}
