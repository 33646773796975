import { faBars, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import appContextNavigation from 'layout/appContextNavigation'
import guestNavDropdownNavigation from 'layout/guestNavDropdownNavigation'
import userNavDropdownNavigation from 'layout/userNavDropdownNavigation'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { type NavListItem } from 'types/navigation'
import LogoImage from '../LogoImage'
import NavItem from './NavItem'
import ThemeToggleButton from './ThemeToggleButton'
import LanguageChanger from '../LanguageChanger'
import { hasPermissions } from 'utils/common'
import { Permission } from 'helpers/permissions'
import { Routes } from 'helpers/routes'

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { data: token } = useSession()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const router = useRouter()
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: any): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isDropdownOpen])

  return (
    <div className="px-4 py-1 w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex-shrink-0">
          <LogoImage link="/" />
        </div>
        <div className="hidden md:flex items-center">
          {appContextNavigation.map((item: NavListItem, index) => {
            const isActive = router.asPath === item.path
            return <NavItem key={index} item={item} highlight={isActive} />
          })}
          <div className="ml-4 relative">
            <div ref={dropdownRef}>
              <button
                className="flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline"
                onClick={() => { setIsDropdownOpen(!isDropdownOpen) }}
              >
                <div className="h-8 w-8 rounded-full bg-gray-200 text-gray-700 flex items-center justify-center text-sm overflow-hidden">
                  {token
                    ? (
                      <div>
                        {token.user.image
                          ? (
                            <img
                              src={token.user.image}
                              alt={`Avatar of ${token.user.name}`}
                              className="h-8 w-8 rounded-full"
                            />
                            )
                          : (
                            <div className="h-8 w-8 rounded-full bg-gray-200 text-gray-700 flex items-center justify-center text-sm overflow-hidden">
                              <span className="truncate">{token.user.name.split(' ').map(n => n[0]).join('').toUpperCase()}</span>
                            </div>
                            )}
                      </div>
                      )
                    : (
                      <FontAwesomeIcon icon={faBars} />
                      )}
                </div>
              </button>
              <div className={`${!isDropdownOpen && 'hidden'} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg`}>
                {/* Not Signed In */}
                {!token && (
                  <div className="py-1 rounded-md shadow-sm bg-light-bg dark:bg-dark-bg">
                    {guestNavDropdownNavigation.map((item: NavListItem, index) => {
                      return <div key={index} className="my-2"><NavItem item={item} tight /></div>
                    })}
                    <div className="flex flex-row gap-4 justify-between mt-8 p-2">
                      <LanguageChanger />
                      <ThemeToggleButton />
                    </div>
                  </div>
                )}
                {/* Signed In */}
                {token && (
                  <div className="py-1 rounded-md shadow-sm bg-light-bg dark:bg-dark-bg">
                    {userNavDropdownNavigation.map((item: NavListItem, index) => {
                      return <div key={index} className="my-2"><NavItem item={item} tight /></div>
                    })}
                    <div className="flex flex-row gap-4 justify-between mt-8 p-2">
                      <LanguageChanger />
                      <ThemeToggleButton />
                    </div>
                    {hasPermissions(token?.user.permissions, [Permission.ADMIN.VIEW.DASHBOARD]) && (
                      <div className="flex flex-row gap-4 justify-between mt-8 p-2">
                        <NavItem
                          item={{
                            title: 'admin_area',
                            path: Routes.ADMIN.DASHBOARD,
                            iconLeft: {
                              icon: faLockOpen,
                              color: 'green-500'
                            }
                          }}
                          tight
                          highlight={router.asPath === '/admin/dashboard'}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <button onClick={() => { setIsMenuOpen(!isMenuOpen) }} className="focus:outline-none">
            <div className="h-1 w-5 bg-gray-700 mb-1"></div>
            <div className="h-1 w-5 bg-gray-700 mb-1"></div>
            <div className="h-1 w-5 bg-gray-700"></div>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden flex flex-col justify-center items-center h-full gap-2">
          {appContextNavigation.map((item: NavListItem, index) => {
            const isActive = router.asPath === item.path
            return <NavItem key={index} item={item} tight highlight={isActive} />
          })}
          <hr className="my-2 mx-2" />
          {/* Signed in */}
          {token && userNavDropdownNavigation.map((item: NavListItem, index) => {
            const isActive = router.asPath === item.path
            return <NavItem key={index} item={item} tight highlight={isActive} />
          })}
          {/* Not signed in */}
          {!token && guestNavDropdownNavigation.map((item: NavListItem, index) => {
            const isActive = router.asPath === item.path
            return <NavItem key={index} item={item} tight highlight={isActive} />
          })}
          <div className="flex flex-row gap-4 justify-between mt-8 p-2">
            <LanguageChanger />
            <ThemeToggleButton />
          </div>
          {token && hasPermissions(token?.user.permissions, [Permission.ADMIN.VIEW.DASHBOARD]) && (
            <div className="flex flex-row gap-4 justify-between mt-8 p-2">
              <NavItem
                item={{
                  title: 'admin_area',
                  path: Routes.ADMIN.DASHBOARD,
                  iconLeft: {
                    icon: faLockOpen,
                    color: 'green-500'
                  }
                }}
                tight
                highlight={router.asPath === '/admin/dashboard'}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Navbar
