import { faAddressCard, faRightFromBracket, faTableColumns } from '@fortawesome/free-solid-svg-icons'
import { Routes } from 'helpers/routes'
import { type NavListItem } from 'types/navigation'

const userNavDropdownNavigation: NavListItem[] = [
  {
    title: 'dashboard',
    path: Routes.USER.DASHBOARD,
    iconLeft: {
      icon: faTableColumns
    }
  },
  {
    title: 'profile',
    path: Routes.USER.PROFILE,
    iconLeft: {
      icon: faAddressCard
    }
  },
  {
    spacer: true,
    marginY: 4
  },
  {
    title: 'signout',
    path: Routes.AUTH.SIGNOUT,
    iconLeft: {
      icon: faRightFromBracket,
      color: 'red-600'
    }
  },
  {
    divider: true,
    marginY: 4
  }
]

export default userNavDropdownNavigation
