import SiteSettings from 'config/settings'
import { useTranslation } from 'react-i18next'
import LogoImage from '../LogoImage'
import { Routes } from 'helpers/routes'
import { type NavListItem } from 'types/navigation'
import NavItem from '../Navbar/NavItem'
import footerNavigation from 'layout/footerNavigation'

const Footer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="py-2">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div className="flex-col items-center md:hidden">
            <div className="flex flex-grow flex-row justify-center">
              <LogoImage link={Routes.USER.DASHBOARD} />
            </div>
          </div>
          <div className="hidden md:flex flex-col items-center text-center">
            <p className="flex-grow text-body2">
              {t('footer_paragraph')}
            </p>
          </div>
          <div className="hidden md:flex md:flex-col md:items-center">
            <div className="flex-grow">
              <LogoImage link={Routes.USER.DASHBOARD} />
            </div>
            <p className="text-center text-body2">{t('footer_tagline')}</p>
            <p className="text-center text-body2">
              © {new Date().getFullYear()} {SiteSettings.GENERAL.NAME}.
            </p>
          </div>
          <div className="flex flex-col items-center">
              {footerNavigation.map((item: NavListItem, index) => {
                return <NavItem key={index} item={item} tight />
              })}
          </div>
          <div className="flex flex-col items-center md:hidden">
            <p className="text-center text-body2">{t('footer_tagline')}</p>
            <p className="text-center text-body2">
              © {new Date().getFullYear()} {SiteSettings.GENERAL.NAME}.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
