interface IResourcePaths {
  // General
  SITE_LOGO_SQUARE: string
  NO_IMAGE: string
  FAVICON: string
  TOOL_ICON_1_SQUARE: string
  GOOGLE_LOGO_SQUARE: string
  GOOGLE_LOGO_LONG: string
  // Auth
  LOCKED: string
  WAVING_HAND: string
  OPEN_HANDS: string
  // Error
  ERROR_401: string
  ERROR_404: string
  ERROR_500: string
  // Pages
  CONTENT: {
    HOME: {
      HERO: string
    }
  }
  // Flags
  FLAGS_DIR: string
}

const ResourcePaths: IResourcePaths = {
  // General
  SITE_LOGO_SQUARE: '/image/logo/logo.png',
  NO_IMAGE: '/image/no-image.png',
  FAVICON: '/favicon.ico',
  TOOL_ICON_1_SQUARE: '/images/icons/tool-icon-1.svg',
  GOOGLE_LOGO_SQUARE: '/images/logos/google.svg',
  GOOGLE_LOGO_LONG: '/images/logos/google-long.svg',
  // Auth
  LOCKED: '/images/icons/locked.png',
  WAVING_HAND: '/images/icons/waving-hand.png',
  OPEN_HANDS: '/images/icons/open-hands.png',
  // Error
  ERROR_401: '/images/pages/401.png',
  ERROR_404: '/images/pages/404.png',
  ERROR_500: '/images/pages/500.png',
  // Pages
  CONTENT: {
    HOME: {
      HERO: '/images/content/background-main.png'
    }
  },
  // Flags
  FLAGS_DIR: '/images/flags'
}
export default ResourcePaths
