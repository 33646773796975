/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Routes } from 'helpers/routes'
import { signOut } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { type NavListItem } from 'types/navigation' // assuming "type" was a typo, correcting to import

interface Props {
  item: NavListItem
  tight?: boolean
  highlight?: boolean
}

const NavItem: React.FC<Props> = ({ item, tight, highlight }) => {
  const { t } = useTranslation()
  if (item.divider) {
    return <hr className={`my-${item.marginY} mx-2`} />
  }

  if (item.sectionLeft ?? item.sectionRight) {
    // Code for item sections here, use Tailwind classes
  }

  if (item.spacer) {
    return <div className={`my-${item.marginY}`}></div>
  }

  if (item.title || item.path || item.iconLeft || item.iconRight) {
    const textColor = 'black'
    // Signout button gets special treatment
    if (item.path === Routes.AUTH.SIGNOUT) {
      return (
        <div className={`p-${tight ? '0' : '4'} flex items-center justify-between w- cursor-pointer`} onClick={async () => { await signOut() }}>
          {item.iconLeft && (
            // Check if the icon is a FontAwesome icon and render accordingly
            typeof item.iconLeft.icon === 'object'
              ? <FontAwesomeIcon icon={item.iconLeft.icon} className={`h-8 w-8 mr-2 text-${item.iconLeft.color}`} />
              : <Image src={item.iconLeft.icon} alt={item.title ?? 'icon'} className="h-8 w-8 mr-2" />
          )}
          <p className={`text-lg font-semibold ${textColor} ${highlight && 'border-b-2 border-primary py-1'} px-2`}>{t(item.title ?? '')}</p>
          {item.iconRight && (
            typeof item.iconRight.icon === 'object'
              ? <FontAwesomeIcon icon={item.iconRight.icon} className={`h-8 w-8 mr-2 text-${item.iconRight.color}`} />
              : <Image src={item.iconRight.icon} alt={item.title ?? 'icon'} className="h-8 w-8 mr-2" />
          )}
        </div>
      )
    }
    return (
      <NavLink path={!item.disabled && item.path}>
        <div className={`p-${tight ? '0' : '4'} flex items-center justify-between w-full`}>
          {item.iconLeft && (
            // Check if the icon is a FontAwesome icon and render accordingly
            typeof item.iconLeft.icon === 'object'
              ? <FontAwesomeIcon icon={item.iconLeft.icon} className={`h-8 w-8 mr-2 text-${item.iconLeft.color}`} />
              : <Image src={item.iconLeft.icon} alt={item.title ?? 'icon'} className="h-8 w-8 mr-2" />
          )}
          <p className={`text-lg font-semibold ${textColor} ${highlight && 'border-b-2 border-primary'} px-2 pb-1`}>{t(item.title ?? '')}</p>
          {item.iconRight && (
            typeof item.iconRight.icon === 'object'
              ? <FontAwesomeIcon icon={item.iconRight.icon} className={`h-8 w-8 mr-2 text-${item.iconRight.color}`} />
              : <Image src={item.iconRight.icon} alt={item.title ?? 'icon'} className="h-8 w-8 mr-2" />
          )}
        </div>
      </NavLink>
    )
  }

  // Default
  return <></>
}

interface NavLinkProps {
  path?: string | false
  children: React.ReactNode
}

const NavLink: React.FC<NavLinkProps> = ({ path, children }) => {
  if (path) {
    return <Link href={path}>{children}</Link>
  }
  return <>{children}</>
}

export default NavItem
