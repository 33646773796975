import { useRouter } from 'next/router'
import { useCallback } from 'react'

const flagMap: Record<string, string> = {
  en: '🇺🇸',
  lt: '🇱🇹'
}

const LanguageChanger: React.FC = () => {
  const router = useRouter()

  const onLocaleChange = useCallback(async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value
    await router.push(router.pathname, router.asPath, { locale: newLocale })
  }, [router])

  return (
      <div
        className='bg-transparent border-2 border-primary rounded-full px-4 py-2 hover:border-secondary'
      >
          <select
              className='bg-transparent rounded-full focus:outline-none'

              value={router.locale}
              onChange={onLocaleChange}
          >
              {router.locales?.map((locale) => (
                  <option key={locale} value={locale}>
                    <div className="flex flex-row gap-2">
                      <span>{flagMap[locale]}</span>
                      <span>{locale.toUpperCase()}</span>
                    </div>
                  </option>
              ))}
          </select>
    </div>
  )
}

export default LanguageChanger
