export const Permission = {
  USER: {
    VIEW: {
      DASHBOARD: 'user.view.dashboard'
    }
  },
  ADMIN: {
    VIEW: {
      DASHBOARD: 'admin.view.dashboard',
      USERS: 'admin.view.users',
      POSTS: 'admin.view.posts',
      ROLES: 'admin.view.roles',
      EMAILS: 'admin.view.emails',
      SETTINGS: 'admin.view.settings',
      FILES: 'admin.view.files'
    },
    CREATE: {
      USERS: 'admin.create.users',
      POSTS: 'admin.create.posts',
      ROLES: 'admin.create.roles',
      EMAILS: 'admin.create.emails',
      SETTINGS: 'admin.create.settings',
      FILES: 'admin.create.files'
    },
    UPDATE: {
      USERS: 'admin.update.users',
      POSTS: 'admin.update.posts',
      ROLES: 'admin.update.roles',
      EMAILS: 'admin.update.emails',
      SETTINGS: 'admin.update.settings',
      FILES: 'admin.update.files'
    },
    DELETE: {
      USERS: 'admin.delete.users',
      POSTS: 'admin.delete.posts',
      ROLES: 'admin.delete.roles',
      EMAILS: 'admin.delete.emails',
      SETTINGS: 'admin.delete.settings',
      FILES: 'admin.delete.files'
    }
  }
}
