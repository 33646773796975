interface ISiteSettings {
  // General
  GENERAL: {
    NAME: string
    CONTACT_EMAIL: string
    SOCIAL: Array<{
      name: string
      url: string
      icon: any
    }>
  }
  // Database
  DATABASE: {
    CACHE_ENABLED: boolean
    CACHE_TTL: number // seconds
  }
  MEDIA: {
    SEARCH_RESULTS_LIMIT: number // FOR EACH MEDIA TRYPE
    MAX_WATCHLIST_LENGTH: number // FOR EACH MEDIA TRYPE
    MOVIE_WATCHLIST_LS_KEY: string
    SHOW_WATCHLIST_LS_KEY: string
    CACHE: boolean
  }
  // API
  API: {
    RATE_LIMITER: {
      MAX_REQUESTS_PER_IP_PER_MINUTE: number
      DELAY_MS: number
    }
  }
  // 3rd party API keys
  MEDIA_METADATA_PROVIDERS: {
    TMDB_API_KEY: string
  }
  YANDEX: {
    METRIKA_TAG_ID: string
  }
}

const SiteSettings: ISiteSettings = {
  // General
  GENERAL: {
    NAME: process.env.SS_SITE_NAME ?? 'KadaIseis',
    CONTACT_EMAIL: process.env.SS_CONTACT_EMAIL ?? 'info@kadaiseis.lt',
    SOCIAL: [
      // {
      //   name: SocialsEnum.TWITTER,
      //   url: `https://twitter.com/${process.env.SS_TWITTER_HANDLE ?? '@searchfrom'}`,
      //   icon: Twitter
      // }
    ]
  },
  // Database
  DATABASE: {
    CACHE_ENABLED: process.env.SS_DB_CACHE_ENABLED === 'true',
    CACHE_TTL: parseInt(process.env.SS_DB_CACHE_TTL ?? '300', 10) // Assuming default value of 300 seconds if not provided
  },
  MEDIA: {
    SEARCH_RESULTS_LIMIT: 3,
    MAX_WATCHLIST_LENGTH: 4,
    MOVIE_WATCHLIST_LS_KEY: 'followedMovies',
    SHOW_WATCHLIST_LS_KEY: 'followedShows',
    CACHE: process.env.SS_MEDIA_CACHE_ENABLED === 'true'
  },
  // API
  API: {
    RATE_LIMITER: {
      MAX_REQUESTS_PER_IP_PER_MINUTE: parseInt(process.env.SS_API_RL_MAX_RPM ?? '50', 10),
      DELAY_MS: parseInt(process.env.SS_API_RL_DELAY_MS ?? '250', 10)
    }
  },
  // 3rd party API keys
  MEDIA_METADATA_PROVIDERS: {
    TMDB_API_KEY: process.env.TMDB_API_KEY ?? ''
  },
  YANDEX: {
    METRIKA_TAG_ID: process.env.YANDEX_METRIKA_TAG_ID ?? ''
  }
}
export default SiteSettings
