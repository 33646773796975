import type { NextPage } from 'next'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import React, { useEffect, useState } from 'react'

import 'styles/globals.css'
import 'tailwindcss/tailwind.css'

import Footer from 'components/common/Footer'
import Navbar from 'components/common/Navbar'
import { useTheme, ThemeProvider } from 'layout/ThemeProvider'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css' // Import the CSS
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import Sidebar from 'components/common/Sidebar'
import adminContextNavigation from 'layout/adminContextNavigation'
import CookieNotice from 'components/common/CookieNotice'

// This will ensure that the icon CSS is loaded immediately before the rest of your app.
config.autoAddCss = false

type ExtendedAppProps = AppProps & {
  Component: NextPage
}
const PROCESS_BAR_COLOR = '#D10808'

const AppContent: React.FC<ExtendedAppProps> = (props) => {
  const { Component, pageProps } = props
  const { theme } = useTheme()
  const router = useRouter()
  const currentPage = router.pathname.slice(1)
  const isDark = theme === 'dark'
  const locale = router.locale ?? 'lt'
  const [seoConfig, setSeoConfig] = useState({})

  useEffect(() => {
    async function fetchSeoConfig (): Promise<void> {
      const { default: getSeoConfig } = await import(`config/seo/${locale}`)
      setSeoConfig(getSeoConfig(currentPage))
    }

    void fetchSeoConfig()
  }, [locale, currentPage])

  if (currentPage.startsWith('admin')) {
    return (
      <div className={`min-h-screen flex flex-col ${isDark ? 'bg-dark-bg text-dark-text' : 'bg-light-bg text-light-text'}`}>
      <NextSeo {...seoConfig} />
      <NextNProgress color={PROCESS_BAR_COLOR} />
        <Sidebar menu={adminContextNavigation}>
          <Component {...pageProps} />
        </Sidebar>
    </div>
    )
  }
  return (
    <div className={`min-h-screen flex flex-col ${isDark ? 'bg-dark-bg text-dark-text' : 'bg-light-bg text-light-text'}`}>
      <NextSeo {...seoConfig} />
      <NextNProgress color={PROCESS_BAR_COLOR} />
      <Navbar />
      <div className="flex flex-col flex-grow">
        <Component {...pageProps} />
        <CookieNotice />
      </div>
      <Footer />
    </div>
  )
}

const App: React.FC<ExtendedAppProps> = (props) => {
  const [hydrated, setHydrated] = React.useState(false)

  useEffect(() => {
    setHydrated(true)
  }, [])

  // Returns null on first render, so the client and server match
  if (!hydrated) {
    return null
  }

  return (
    <SessionProvider basePath='/api/v1/auth'>
      <ThemeProvider>
        <AppContent {...props} />
      </ThemeProvider>
    </SessionProvider>
  )
}

export default appWithTranslation(App)
