// components/ThemeToggleButton.tsx
import React from 'react'
import { useTheme } from 'layout/ThemeProvider'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ThemeToggleButton: React.FC = () => {
  const { theme, toggleTheme } = useTheme()

  return (
    <button
      className='bg-transparent text-white border-2 border-primary rounded-full px-4 py-2 hover:border-secondary'
      onClick={toggleTheme}
    >
      {theme === 'light'
        ? (
          <span className="text-gray-600 dark:text-gray-300">
            <FontAwesomeIcon icon={faMoon} />
          </span>
          )
        : (
          <span className="text-yellow-500">
            <FontAwesomeIcon icon={faSun} />
          </span>
          )}
    </button>
  )
}

export default ThemeToggleButton
