import { type NavListItem } from 'types/navigation'
import { Routes } from 'helpers/routes'

const footerNavigation: NavListItem[] = [
  {
    title: 'terms',
    path: Routes.PUBLIC.TERMS
  },
  {
    title: 'about',
    path: Routes.PUBLIC.ABOUT
  },
  {
    title: 'faq',
    path: Routes.PUBLIC.FAQ
  }
]

export default footerNavigation
