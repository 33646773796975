import { type NavListItem } from 'types/navigation'
import { Routes } from 'helpers/routes'
import { faList } from '@fortawesome/free-solid-svg-icons'

const appContextNavigation: NavListItem[] = [
  {
    title: 'posts',
    path: Routes.PUBLIC.POSTS,
    iconLeft: {
      icon: faList
    }
  },
  {
    title: 'watchlist',
    path: Routes.PUBLIC.WATCHLIST,
    iconLeft: {
      icon: faList
    }
  },
  {
    spacer: true,
    marginY: 4
  }
]

export default appContextNavigation
