import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const CookieNotice: React.FC = () => {
  const { t } = useTranslation()
  const [showCookieNotice, setShowCookieNotice] = useState(false)

  useEffect(() => {
    if (Cookies.get('cookieNotice') === undefined) {
      setShowCookieNotice(true)
    }
  }, [])

  const handleAccept = useCallback(() => {
    setShowCookieNotice(false)
    setTimeout(() => {
      Cookies.set('cookieNotice', 'accept')
    }, 500)
  }, [])

  return (
    <div
      className={`fixed bottom-0 w-full bg-primary text-white z-40 transition-opacity duration-500 ${
        showCookieNotice ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="flex flex-row items-center container mx-auto px-4 py-2">
        <p className="text-center">{t('cookie_text')}</p>
        <button onClick={handleAccept} className="ml-auto">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  )
}

export default CookieNotice
