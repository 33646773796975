import bcrypt from 'bcryptjs'
import { ErrorMessagesEnum } from 'helpers/enums/ErrorMessagesEnum'
import { type PaginationData, type PaginationMeta } from 'types/data'

interface Params {
  reference: string[]
  object: object
}

/**
 * Removes extra properties from an object. Useful for filtering out unwanted properties from a request body
 * @param param0
 * @returns
 */
export const filterExtraObjectProperties = ({ reference, object }: Params): any => {
  return Object.fromEntries(Object.entries(object).filter(([key]) => reference.includes(key)))
}

/**
 * Returns a hashed password
 * @param param0
 * @returns string
 */
export const hashPassword = ({ password }: {
  password: string
}): string => {
  return bcrypt.hashSync(password, 13)
}

/**
 * Compares a hashed password, usually from database with an unhashed password, usually from a request body
 * @param param0
 * @returns boolean
 */
export const comparePasswords = ({ unhashedPassword, hashedPassword }: {
  unhashedPassword: string
  hashedPassword: string
}): boolean => {
  return bcrypt.compareSync(unhashedPassword, hashedPassword)
}

/**
 * Checks if a user has all the permissions in the needles array
 * @param haystack
 * @param needles
 * @returns boolean
 */
export const hasPermissions = (haystack: string[], needles: string[]): boolean => {
  return needles.every(needle => haystack.includes(needle))
}

/**
 * Check if needle starts with any item in haystack
 * @param needle
 * @param haystack
 * @returns boolean
 */
export const needleStartsWithAnyInHaystack = (needle: string, haystack: string[]): boolean => {
  return haystack.some(h => needle.startsWith(h))
}

/**
 * Formats a number to currency. If number is not provided, it returns the currency symbol
 * @param currency
 * @param number
 * @returns string
 */
export const formatCurrency = (
  currency: string,
  number?: number
): string => {
  if (number != null) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    }).format(number)
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).format(0)[0]
}

/**
 * Builds an error message from an error object
 * @param e
 * @returns string
 */
export const buildErrorMessage = (e: any): string => {
  try {
    return Object.values(ErrorMessagesEnum).includes(e.message) ? e.message : ErrorMessagesEnum.OPERATION_FAILED
  } catch (e) {
    return ErrorMessagesEnum.OPERATION_FAILED
  }
}

/**
 * Converts an array of models to a pagination data object
 * @param models
 * @returns PaginationData<any>
 */
export const converToPaginationData = (models: any[]): PaginationData<any> => {
  const data = models
  const meta: PaginationMeta = {
    current_page: 1,
    from: 1,
    last_page: 1,
    to: models?.length ?? '',
    total: models?.length ?? 0
  }
  return { data, meta }
}
