import ResourcePaths from 'config/resources'
import SiteSettings from 'config/settings'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useTheme } from 'layout/ThemeProvider'

const logoUrl = ResourcePaths.SITE_LOGO_SQUARE

interface Props {
  link?: string
}

const LogoImage: React.FC<Props> = ({ link }) => {
  const { theme } = useTheme()

  return link != null
    ? (
            <Link href={link}>
                <div className="flex items-center gap-1 mb-2 cursor-pointer">
                    <Image src={logoUrl} alt="logo" width={50} height={50} />
                    <p
                        className={`font-sriracha font-semibold text-3xl leading-none tracking-tighter lowercase ${theme === 'light' ? 'text-black' : 'text-white'}`}>
                        {SiteSettings.GENERAL.NAME}
                    </p>
                </div>
            </Link>
      )
    : (
            <div className="flex items-center gap-2 mb-2">
                <Image src={logoUrl} alt="logo" width={50} height={50} />
                <p
                    className={`font-sriracha font-semibold text-3xl leading-none tracking-tighter lowercase ${theme === 'light' ? 'text-black' : 'text-white'}`}>
                    {SiteSettings.GENERAL.NAME}
                </p>
            </div>
      )
}

export default LogoImage
