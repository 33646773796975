import { type NavListItem } from 'types/navigation'
import { Routes } from 'helpers/routes'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'

const guestNavDropdownNavigation: NavListItem[] = [
  {
    title: 'signin',
    path: Routes.AUTH.SIGNIN,
    iconLeft: {
      icon: faRightToBracket
    }
  },
  {
    divider: true,
    marginY: 4
  }
]

export default guestNavDropdownNavigation
