import { faEnvelope, faGear, faHouse, faLock, faNewspaper, faRightFromBracket, faTableColumns, faUser } from '@fortawesome/free-solid-svg-icons'
import { Routes } from 'helpers/routes'
import { type NavListItem } from 'types/navigation'

const adminContextNavigation: NavListItem[] = [
  {
    title: 'home',
    path: Routes.PUBLIC.HOME,
    iconLeft: {
      icon: faHouse
    }
  },
  {
    spacer: true,
    marginY: 4
  },
  {
    title: 'dashboard',
    path: Routes.ADMIN.DASHBOARD,
    iconLeft: {
      icon: faTableColumns
    }
  },
  {
    title: 'users',
    path: Routes.ADMIN.USERS,
    iconLeft: {
      icon: faUser
    }
  },
  {
    title: 'posts',
    path: Routes.ADMIN.POSTS,
    iconLeft: {
      icon: faNewspaper
    }
  },
  {
    title: 'roles',
    path: Routes.ADMIN.ROLES,
    iconLeft: {
      icon: faLock
    }
  },
  {
    title: 'emails',
    path: Routes.ADMIN.EMAILS,
    iconLeft: {
      icon: faEnvelope
    }
  },
  {
    title: 'settings',
    path: Routes.ADMIN.SETTINGS,
    iconLeft: {
      icon: faGear
    }
  },
  {
    spacer: true,
    marginY: 4
  },
  {
    title: 'signout',
    path: Routes.AUTH.SIGNOUT,
    iconLeft: {
      icon: faRightFromBracket
    }
  },
  {
    divider: true,
    marginY: 4
  }
]

export default adminContextNavigation
